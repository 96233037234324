@import "./_variables.less";
@import "./_utils.less";

.hide {
  display: none;
}

.full-skeleton {
  .ant-skeleton-element {
    width: 100%;
  }
}